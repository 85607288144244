import React from "react";
import styled from "styled-components";
import {Container} from "../../../../../../components/container";
import {Colors} from "../../../../../../components/colors/colors";
import {Row} from "../row";

const Table = () => (
  <Wrapper>
    <Container>
    <Row>Best practices in Corporate, Governance, and Risk Management</Row>
    <Row>Anti-Money Laundering/Counter Terrorism Financing (AML/CTF)</Row>
    <Row style={{borderStyle: "none"}}>Credit Risk Management</Row>
    </Container>
  </Wrapper>
);

const Wrapper = styled(Container)`
  max-width: 540px;
  background-color: ${Colors.cloud}; 
`;


export {Table};