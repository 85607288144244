import React from "react";
import {Article} from "../../../../../components/article/article";
import styled from "styled-components";
import {
      StyledTextParagraphLarge,
      StyledTextParagraphSmall
} from "../../../../../components/styledText";
import {Colors} from "../../../../../components/colors";
import "../style.css"

const PersonalInfoUse = () => (
  <Article
    headerText={
          <Header>Use of personal information</Header>
    }
    headerColor={Colors.black}
    headerClass={"section-header"}
    bodyText={
      <Body>
      <p>
      We automatically gather information to monitor the use of our Site, mobile application,
      software application and provision of our services. Much of the data we collect is
      aggregated, and this information is effectively anonymous to us.
      </p>
      <p>
      In general we use personal information for providing, evaluating, improving,
      personalising and developing our business, our Site, mobile and software applications
      and our services. More specifically we use personal information for:
      </p>
      <p>
          • establishing and maintaining your relationship as a customer of TransCo; <br/>
          • providing the products and services you have requested from TransCo; <br/>
          • administering and managing the products and services provided to you; <br/>
          • to answer your enquiries and provide you support; <br/>
          • improving user satisfaction; <br/>
          • promoting and marketing our services to you (each person may opt-out
            of marketing and sales communications); <br/>
          • providing such information to third party providers of goods and services
            to enable them to market to you either through our software application or directly; <br/>
          • our internal research and statistical purposes (including market<br/>
          segmentation, market analysis, trend identification and customer value analysis); and <br/>
          • enabling us to forward to you other information or material which we believe<br/>
            may be of interest to you (each person may opt-out of our information sharing communications).
      </p>
      </Body>
    }
  />);

const Header = styled(StyledTextParagraphLarge)``;


const Body = styled(StyledTextParagraphSmall)`
`;

export {PersonalInfoUse};