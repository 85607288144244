import React from "react";
import {Article} from "../../../../components/article/article";
import {Colors} from "../../../../components/colors";
import {SplitArticleSection} from "../../../../components/splitSection";
import styled from "styled-components";
import {Container} from "../../../../components/container";
import {StyledTextParagraphLarge} from "../../../../components/styledText";
import {Table} from "./components/table";
import "./style.css"

const GovernanceAndRisk = () => (
  <SplitArticleSection
    left={
      <Article
        headerStyle={{alignItems: "left"}}
        headerText={"Governance and Risk Management"}
        wrapperClass={"gov-section-text"}
        bodyText={
          <StyledTextParagraphLarge>
            Our Payments Methodology incorporates world best practices in regards to Corporate,
            Governance, Risk Management, Anti-Money Laundering/Counter Terrorism Financing (AML/CTF)
            and Credit Risk Management.
            <br/>
            <br/>
            By using TransCo’s Payment’s Methodology you essentially outsource your payments risk to us.
          </StyledTextParagraphLarge>}
      />
    }
    leftBackground={Colors.white}
    right={
      <Table/>
    }
    rightBackground={Colors.white}
    rightClass={"gov-section-table"}
  />
);



export {GovernanceAndRisk}