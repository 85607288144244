import React from 'react';
import styled from "styled-components";
import {Footer} from "../footer";
import {Container} from "../container";
import {PageHeader} from "../pageheader";

const Layout = ({children, headerText}) => (
  <Wrapper>
    <PageHeader headerText={headerText}/>
    <Container>
      {children}
    </Container>
    <Footer/>
  </Wrapper>
);


const Wrapper = styled(Container)``;

export {Layout};