import React from "react";
import { Colors } from "../colors";
import { Container } from "../container";
import { MaxPageWidth } from "../constants";
import "./styles.css"

const maxWidthContainerStyles = {
  container: {
    maxWidth: MaxPageWidth / 2
  }
};
const styles = {
  container: {
    backgroundColor: Colors.cloud
  },
  children: {
    flex: 1
  }
};

const MaxWidthContainer = ({ style, ...rest }) => (
  <Container
    style={{ ...maxWidthContainerStyles.container, ...style }}
    {...rest}
  />
);

const SplitSection = ({
  left,
  right,
  leftBackground = Colors.orange,
  rightBackground = Colors.flame,
  style,
  flipped = false,
  minHeight
}) => (
  <Container style={{ ...styles.container, ...style }} className={`splitSection ${flipped? "flipped" : ""}`}>
    {left && (
      <Container
        flex={1}
        style={{ backgroundColor: leftBackground, flexBasis: "50%", minHeight}}
      >
        {left}
      </Container>
    )}
    {right && (
      <Container
        flex={1}
        style={{ backgroundColor: rightBackground, flexBasis: "50%"}}
      >
        {right}
      </Container>
    )}
  </Container>
);

const SplitArticleSection = ({
  left,
  right,
  leftBackground = Colors.orange,
  rightBackground = Colors.flame,
  leftClass = "splitSectionLeft",
  rightClass = "splitSectionRight",
  ...rest
}) => (
  <SplitSection
    left={
      left && (
        <Container flex={1} flexDirection="row" style={styles.children} className={leftClass} alignItems={"flexEnd"}>
          <MaxWidthContainer>{left}</MaxWidthContainer>
        </Container>
      )
    }
    right={
      right && (
        <Container flex={1} flexDirection="row" style={styles.children} className={rightClass} alignItems={"flexStart"}>
          <MaxWidthContainer>{right}</MaxWidthContainer>
        </Container>
      )
    }
    leftBackground={leftBackground}
    rightBackground={rightBackground}
    {...rest}
  />
);

export { SplitSection, SplitArticleSection };
