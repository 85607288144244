import React from "react";
import { SplitArticleSection } from "../../../../../components/splitSection";
import { Colors } from "../../../../../components/colors";
import { Article } from "../../../../../components/article/article";
import { commonStyles } from "../commonStyles";
import { Container } from "../../../../../components/container";
import image from "./World-Class-Technology.png";
import "./styles.css"

const WorldClass = () => (
  <SplitArticleSection
    left={
        <Article
            headerText={"World Class Platform"}
            headerColor={Colors.darkBlue}
            wrapperClass={"article-left"}
            bodyText={`
        We have applied our 80 years of combined payments experience into building an innovative approach to payments facilitation.
        Our payments methodology, coupled with our technology, transforms the old way of processing payments to a more tightly integrated, homonengensis, approach from which service providers transact.
      `}
            bodyColor={Colors.blackSecondary}
        />
    }
  leftBackground={Colors.white}
    right={
      <Container
        flex={1}
        alignItems={"center"}
        className={"article-right"}
      >
        <img alt={"img"} src={image} width={250} />
      </Container>
    }
    rightClass={"splitSectionRightImage"}
    rightBackground={Colors.white}
  />
);

export { WorldClass };
