import React from "react";
import { Colors } from "../colors";
import { Container } from "../container";
import {StyledTextHeading2, StyledTextParagraphLarge} from "../styledText";
import "./style.css"

const Article = ({
  style,
  bodyStyle,
  headerStyle,
  headerText,
  bodyText,
  headerColor = Colors.darkBlue,
  bodyColor = Colors.blackSecondary,
  wrapperClass = "container",
  headerClass = "header-text",
  bodyClass = "body-text",
}) => (
  <Container style={style} className={wrapperClass}>
    <StyledTextHeading2 color={headerColor} className={headerClass} style={headerStyle}>
      {headerText}
    </StyledTextHeading2>
    <StyledTextParagraphLarge  color={bodyColor} className={bodyClass} style={bodyStyle}>
      {bodyText}
    </StyledTextParagraphLarge>
  </Container>
);

export { Article };
