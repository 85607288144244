import React from "react";
import { Colors } from "../../../../../components/colors";
import { Article } from "../../../../../components/article/article";
import { Container } from "../../../../../components/container";
import { commonStyles } from "../commonStyles";
import "./style.css"

const styles = {
  container: {
    paddingTop: 64,
    paddingBottom: 64,
    alignItems: "center",
    backgroundColor: Colors.darkBlue
  },
  article: {
    alignItems: "center",
    ...commonStyles.articleFull,
    maxWidth: 590

  }
};

const OutcomeMatters = () => (
  <Container style={styles.container}>
    <Article
      style={styles.article}
      bodyClass={"outcome-matters"}
      headerText={"Outcomes That Matter"}
      headerColor={Colors.teal}
      headerStyle={styles.header}
      bodyText={`
            TransCo has the product development and marketing resources to execute a complete go to market strategy with which to connect with your audience.
            This breadth and depth of expertise positions TransCo as the definitive technology platform that you can build your business on. Finally.
          `}
      bodyColor={Colors.white}
      bodyStyle={styles.body}
    />
  </Container>
);
export { OutcomeMatters };
