import React from "react";
import logo from "./logoDark.png"
import styled from "styled-components";
import { Container } from "../../container";
import './style.css'
import {Link} from "react-router-dom";

const Logo = () => (
    <Wrapper flex={1} alignItems={"flex-end"}>
      <Link to={"/"}>
      <Image className={'logo-style'} src={logo} alt={"logo"} />
      </Link>
    </Wrapper>
);

const Wrapper = styled(Container)``;

const Image = styled.img`
  width: 30px;
`;

export { Logo };
