import React from "react";

const defaultStyle = {
  display: "flex",
  boxSizing: "border-box"
};

const Container = ({
  style,
  flex = "auto",
  flexDirection = "column",
  justifyContent = "auto",
  alignItems = "auto",
  ...rest
}) => (
  <div
    {...rest}
    style={{
      ...defaultStyle,
      flex,
      flexDirection,
      justifyContent,
      alignItems,
      ...style
    }}
  />
);

export { Container };
