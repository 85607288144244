import React from "react";
import styled from "styled-components";
import {Container} from "../../../../../../components/container";
import {StyledTextParagraphLarge} from "../../../../../../components/styledText";
import image from "./image.png"
import {Colors} from "../../../../../../components/colors";
import "./style.css"

const Row = ({children, style}) => (
  <Wrapper flex={"90%"} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} style={style}>
    <Text color={Colors.black} className={"row-text"}>
      <StyledTextParagraphLarge>{children}</StyledTextParagraphLarge>
    </Text>
    <Tick src={image}/>
  </Wrapper>
);

const Wrapper = styled(Container)`
  margin-left: 32px;
  margin-right: 32px; 
  border-color: ${Colors.tableBorder}; 
  border-bottom-style: solid;
  border-width: 1px;
`;

const Text = styled(Container)`
  margin-top: 32px;  
  margin-bottom: 32px;
`;

const Tick = styled.img`
  width: 24px;
  height: 17px;
  position: "relative";
  left: 32px;
`;

export {Row};