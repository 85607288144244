import React from "react";
import { Header } from "./components/header";
import { Container } from "../../components/container";
import { Footer } from "../../components/footer";
import {
  OurPeople,
  TheOpportunity,
  OutcomeMatters,
  ProvenPhilosophy,
  WorldClass,
  MapsAddresses
} from "./components/articles";

const Home = () => (
  <Container flex={1}>
    <Header />
    <ProvenPhilosophy />
    <TheOpportunity />
    <WorldClass />
    <OutcomeMatters />
    <OurPeople />
    <MapsAddresses />
    <Footer />
  </Container>
);

export { Home };
