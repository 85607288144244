import React from 'react';
import {Layout} from "../../components/layout";
import {GovernanceAndRisk} from "./components/governanceAndRisk";
import {Security} from "./components/security";
import {IndustryMarketplace} from "./components/industryMarketplace";
import {GetInTouch} from "./components/getInTouch";


const OurApproach = () => (
  <Layout headerText={"Our Approach"}>
    <GovernanceAndRisk/>
    <Security/>
    <IndustryMarketplace/>
    <GetInTouch/>
  </Layout>
);

export {OurApproach};
