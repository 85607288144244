import React from "react";
import {Article} from "../../../../../components/article/article";
import styled from "styled-components";
import {
      StyledTextParagraphLarge,
      StyledTextParagraphSmall
} from "../../../../../components/styledText";
import {Colors} from "../../../../../components/colors";
import "../style.css"

const PersonalInfo = () => (
  <Article
    headerText={
          <Header>Collecting personal information</Header>
    }
    headerColor={Colors.black}
    headerClass={"section-header"}
    bodyText={
      <Body>
      <p>
        Personal information is information or an opinion of an individual
      whose identity is apparent or can be reasonably ascertained.
      We store a range of personal information that is provided as a part
      of our business and through the use of our products and services.
      This information is collected from a wide variety of sources including:
      </p>
      <p>
      • through our website www.TransCo.io;<br/>
      • through our TransCo mobile application;<br/>
      • through our TransCo software application; <br/>
      • from you conducting business or intending to conduct business with us, whether past, present or future;<br/>
      • by otherwise having some form of contact with us such as such as by mail,
      telephone, email, internet or intranet, or use of our software application; and/or <br/>
      • through any of our other business activities or events
      </p>
      </Body>
    }
  />);

const Header = styled(StyledTextParagraphLarge)``;


const Body = styled(StyledTextParagraphSmall)`
`;

export {PersonalInfo};