const Colors = {
  white: "#ffffff",
  cloud: "#f6f6f6",
  orange: "#f7976c",
  flame: "#ffe9e1",
  lightBlue: "#d4f2fc",
  blue: "#6ed0f6",
  darkBlue: "#483291",
  lightGrey: "#e5e5e5",
  blackSecondary: "#818181",
  teal: "#2DBDB6",
  sky: "#72D0F4",
  skySecondary: "rgba(113, 207, 244, 0.2)",
  black: "#000000",
  tableBorder: 'rgba(0, 0, 0, 0.2)',
  misc: 'rgba(0, 0, 0, 0.1)'
};

export { Colors };
