import React from "react";
import {Article} from "../../../../../components/article/article";
import styled from "styled-components";
import {
  StyledTextParagraphLarge,
  StyledTextParagraphSmall
} from "../../../../../components/styledText";
import {Colors} from "../../../../../components/colors";

const Anonymity = () => (
  <Article
    headerText={
      <Header>
        Anonymity
      </Header>}
    headerColor={Colors.black}
    headerClass={"section-header"}
    bodyText={
      <Body>
        <p>
        Due to the nature of the services we provide it is generally impractical
        for us to deal with you without knowing your name and your contact details.
        However, where it is practical for us to do so, we shall provide you with
        the option to deal with us anonymously or by using a pseudonym.
        </p>
      </Body>
    }
  />);

const Header = styled(StyledTextParagraphLarge)``;

const Body = styled(StyledTextParagraphSmall)`
`;

export {Anonymity};