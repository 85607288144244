import React from "react";
import { Colors } from "../colors/colors";
import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledText = ({ color, style, children, ...rest}) => {
  return (<span {...rest} style={{ color, ...style }}>
    {children}
  </span>
  );
};

const StyledTextHeading1 = ({style, children, color, ...rest}) => {
  return(
    <StyledText
      color={color}
      style={{ ...style, fontSize: 44, lineHeight: "60px"}}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

const StyledTextHeading2 = ({style, children, color, ...rest}) => {
  return(
    <StyledText
      color={color}
      style={{ ...style, fontSize: 32, lineHeight:"48px"}}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

const StyledTextParagraphHeader = ({style, children, color,...rest}) => {
  return(
    <StyledText
      color={color}
      style={{ ...style, fontSize: 24, lineHeight: "40px"}}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

const StyledTextParagraphLarge = ({style, children, color,...rest}) => {
  return(
    <StyledText
      color={color}
      style={{ ...style, fontSize: 20, lineHeight: "28px"}}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

const StyledTextParagraphMedium = ({style, children, color, ...rest}) => {
  return(
    <StyledText
      color={color}
      style={{ ...style, fontSize: 16, lineHeight: "24px"}}
      {...rest}
    >
      {children}
    </StyledText>
  );
};

const StyledTextParagraphSmall = ({style, children, color,...rest}) => {
  return(
    <StyledText
      color={color}
      style={{ ...style, fontSize: 14, lineHeight: "20px"}}
      {...rest}
    >
      {children}
    </StyledText>
  );
};


const StyledTextLink = styled(Link)`
  color: ${Colors.darkBlue};
  text-decoration: underline; 
`;

const StyledTextLinkAnchor = styled.a`
  color: ${Colors.darkBlue};
  text-decoration: underline; 
`;

export { StyledText, StyledTextHeading1,StyledTextHeading2,StyledTextParagraphHeader,StyledTextParagraphLarge,StyledTextParagraphMedium,StyledTextParagraphSmall,StyledTextLink,StyledTextLinkAnchor};
