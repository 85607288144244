import { SplitArticleSection } from "../../../../../components/splitSection";
import { Article } from "../../../../../components/article/article";
import { Colors } from "../../../../../components/colors";
import React from "react";
import image from "./Intelligent-platform.png";
import { Container } from "../../../../../components/container";
import "../style.css"


const TheOpportunity = () => (
  <SplitArticleSection
    left={
      <Container
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
        className={"article-left"}
      >
        <img alt={"img"} src={image} width={250} />
      </Container>
    }
    leftBackground={Colors.orange}
    right={
      <Article
        headerText={"The Opportunity"}
        bodyText={`
          TransCo views payments as core to any type of service delivery.
          We present an opportunity for service providers to reconceptualize payments, with profit as a clear objective. 
          Leveraging our technology, relationships and deep payments knowledge in partnership with the service providers’ industry strengths.
        `}
        bodyColor={Colors.blackSecondary}
        wrapperClass={"article-right"}
      />
    }
    rightBackground={Colors.flame}
    flipped={true}
  />
);

export { TheOpportunity };
