import React from "react";
import {Article} from "../../../../../components/article/article";
import styled from "styled-components";
import {
      StyledTextParagraphLarge,
      StyledTextParagraphSmall
} from "../../../../../components/styledText";
import {Colors} from "../../../../../components/colors";
import "../style.css"

const PersonalInfoCollected = () => (
  <Article
    headerText={
          <Header>Personal information collected by us may include:</Header>
    }
    headerColor={Colors.black}
    headerClass={"section-header"}
    bodyText={
      <Body>
      <p>
      • your name, gender or age;  <br/>
      • your contact details such as your mobile number, telephone number and email address; <br/>
      • your passport information;  <br/>
      • your drivers license  <br/>
      • your credit card or banking information;  <br/>
      • your ABN  <br/>
      • all logs, which may include information such as your internet protocol (IP) 
      address, browser type, browser version, clickstream data, referring URLs, the pages of our Site that you visit,
      the time spent on any pages of our Site or software application and other log related information relating to
      your use of our Site or software application. 
      </p>
      <p>
      We endeavour to only collect personal information about you where it is reasonably required.
      </p>
      </Body>
    }
  />);

const Header = styled(StyledTextParagraphLarge)``;


const Body = styled(StyledTextParagraphSmall)`
`;

export {PersonalInfoCollected};