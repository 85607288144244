import React from "react";
import styled from "styled-components";
import { Container } from "../container";
import { Colors } from "../colors";
import { MaxPageWidth } from "../constants";
import {Nav} from "./nav";
import {Contact} from "./contact";
import {FooterText} from "./footerText";
import {Logo} from "./logo/logo";
import "./style.css"

const Footer = () => (
  <Wrapper alignItems={"center"}>
    <InnerContainer justifyContent={"center"} className={"footer-wrapper"}>
      <Top className={"top"}>
        <Contact/>
        <Nav/>
        <Logo/>
      </Top>
      <Bottom>
        <FooterText/>
      </Bottom>
    </InnerContainer>
  </Wrapper>
);

const Wrapper = styled(Container)`
  background-color: ${Colors.cloud}; 
`;

const InnerContainer = styled(Container)`
  width: 100%; 
  max-width: ${MaxPageWidth}px; 
`;

const Top = styled(Container)``;

const Bottom = styled(Container)``;





export { Footer };
