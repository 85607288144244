import React from "react";
import {SplitArticleSection} from "../../../../../components/splitSection";
import {Article} from "../../../../../components/article/article";
import {Colors} from "../../../../../components/colors";
import {Container} from "../../../../../components/container";
import image from "./ourpeople.png";
import {StyledTextLinkAnchor, StyledTextParagraphLarge} from "../../../../../components/styledText";
import "../style.css"

const styles = {
  contactUs: {
    color: Colors.darkBlue,
  }
};

const OurPeople = () => (
  <SplitArticleSection
    left={
      <Container className={"article-left"}>
      <Article
        headerText={"Our People"}
        headerColor={Colors.darkBlue}
        bodyText={`
          If you are interested in a culture that is passionate and driven,
          and believe you can contribute to a new generation of payments thinking
          and transparency, we would love to hear from you`}
        bodyColor={Colors.blackSecondary}
      />
        <StyledTextLinkAnchor href={"mailto:hello@transco.io"}>
          <StyledTextParagraphLarge style={styles.contactUs}>Contact Us</StyledTextParagraphLarge>
        </StyledTextLinkAnchor>
      </Container>
    }
    leftBackground={Colors.lightBlue}
    right={
      <Container
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
        className={"article-right"}
      >
        <img alt={"img"} src={image} width={250} />
      </Container>
    }
    rightBackground={Colors.blue}
  />
);



export { OurPeople };
