import React from "react";
import {Home, OurApproach,PrivacyPolicy} from "./pages";
import { Route } from "react-router-dom";

function App() {
  return (
    <>
    <Route exact path="/" component={Home} />
    <Route exact path="/our-approach" component={OurApproach}/>
    <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
  </>
  )
}

export default App;
