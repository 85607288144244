import React from "react";
import {Article} from "../../../../components/article/article";
import {Container} from "../../../../components/container";
import {StyledTextLink, StyledTextParagraphLarge} from "../../../../components/styledText";
import {Colors} from "../../../../components/colors/colors";
import styled from "styled-components";
import {commonStyles} from "../../../home/components/articles/commonStyles";
import image from "./image.png"
import "./style.css"

const styles = {
  container: {
    paddingTop: 64,
    paddingBottom: 64,
    alignItems: "center",
    backgroundColor: Colors.skySecondary,
    width: "100%"
  },
  article: {
    ...commonStyles.articleFull,
    maxWidth: 590
  },
  header: {
    paddingBottom: 24
  }
};

const Security = () => (
  <Container style={styles.container}>
  <Article
    style={styles.article}
    headerClass={"security"}
    headerText={"Security and Privacy"}
    headerColor={Colors.darkBlue}
    headerStyle={styles.header}
    bodyText={
      <BodyText>
        Our Payments Methodology operates world best practice in regards to
        Data Security and Privacy (
        <StyledTextLink target="_blank" href="/privacy_policy">
          Privacy Policy
        </StyledTextLink>).
        <br/>
        <br/>
        All credit card data is secured under the comprehensive Level 1 PCI DSS standard (set by the card schemes)
        and independently audited by Bank certified Qualified Security Auditors (QSA).
      </BodyText>
    }
    bodyColor={Colors.blackSecondary}
    bodyClass={"security"}
  />
    <Image alt={"img"} src={image} className={"stamps"}/>
  </Container>
);

const BodyText = styled(StyledTextParagraphLarge)`
  max-width: 590px; 
  padding-top: 24px; 
`;

const Image = styled.img`
  padding-top: 40px; 
`;

export {Security}