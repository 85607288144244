import React from "react";
import styled from "styled-components";
import { Container } from "../../container";
import {
  StyledTextLink,
  StyledTextParagraphHeader,
  StyledTextParagraphLarge
} from "../../styledText";


const Nav = () => (
  <Wrapper>
    <Heading>
      TransCo
    </Heading>
    <Buttons>
        <StyledTextLink to="/our-approach">
            Our Approach
        </StyledTextLink>
    </Buttons>
  </Wrapper>
);


const Wrapper = styled(Container)`
  margin-bottom: 64px; 
`;

const Heading = styled(StyledTextParagraphHeader)`
  padding-bottom: 16px; 
`;

const Buttons = styled(StyledTextParagraphLarge)``;

export { Nav };
