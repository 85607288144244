import React from "react";
import styled from "styled-components";
import { Container } from "../../container";
import {
  StyledTextParagraphSmall,
  StyledTextLink
} from "../../styledText";

const FooterText = () => (
  <Wrapper
    flexDirection="column"
    alignItems="center"
    justifyContent="flex-end">
      <Copyright>
        © Copyright 2019. TransCo.
        <Link to="/privacy-policy" >
          Privacy Policy
        </Link>
      </Copyright>

  </Wrapper>
);

const Wrapper = styled(Container)`
`;

const Copyright = styled(StyledTextParagraphSmall)`
`;

const Link = styled(StyledTextLink)`
 padding-left: 6px

`;

export { FooterText };
