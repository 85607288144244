import React from "react";
import { SplitArticleSection } from "../../../../../components/splitSection";
import { Colors } from "../../../../../components/colors";
import { Article } from "../../../../../components/article/article";
import image from "./A-Proven-Philosophy.png"
import {Container} from "../../../../../components/container";
import "../style.css"


const ProvenPhilosophy = () => (
  <SplitArticleSection
    left={
      <Article
        headerText={"Our Proven Philosophy"}
        headerColor={Colors.darkBlue}
        bodyText={`
          In most industries the payments process is a black box but a necessary component of customer service delivery.
          TransCo is an enabler, it breaks open the black box. 
          Allowing for an industry or marketplace service providers to transparently bring payments into their profit fold.
        `}
        bodyColor={Colors.blackSecondary}
        wrapperClass={"article-left"}
      />
    }
    leftBackground={Colors.white}
    right={<Container
      flex={1}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <img alt={"img"} src={image} width={250} />
    </Container>}
    rightBackground={Colors.white}
    rightClass={"splitSectionRightImage"}
  />
);
export { ProvenPhilosophy };
