import React from "react";
import {SplitSection} from "../../../../../components/splitSection";
import {Container} from "../../../../../components/container";
import syd from "./syd.png";
import melb from "./melb.png";
import {StyledTextParagraphLarge} from "../../../../../components/styledText";
import {Colors} from "../../../../../components/colors";
import {commonStyles} from "../commonStyles";

const styles = {
  container: {
    flexDirection: "column",
    height: "auto"
  },
  address: {
    paddingTop: 56,
    paddingBottom: 56,
  },
  a: {
    display: "flex",
  },
  img: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 368,
  }
};

const MapsAddresses = () => (
  <SplitSection
    left={
      <Container style={styles.container} flex={1}>
        <Container style={styles.address} flexDirection={"row"} justifyContent={"flex-end"}>
          <Container alignItems={"center"} style={{...commonStyles.articleSmall}}>
            <StyledTextParagraphLarge color={Colors.orange}>TransCo Sydney</StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> Level 2 </StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> 80 Clarence St </StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> Sydney NSW </StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> 2000 Australia </StyledTextParagraphLarge>
          </Container>
        </Container>
        <a style={styles.a} target={"_blank"}
           href="https://www.google.com.au/maps/place/80+Clarence+St,+Sydney+NSW+2000">
          <Container
            style={{...styles.img, backgroundImage: `url(${syd})`}}
          />
        </a>
      </Container>
    }
    leftBackground={Colors.darkBlue}
    right={
      <Container style={styles.container} flex={1}>
        <Container style={styles.address} flexDirection={"row"} justifyContent={"flex-start"}>
          <Container alignItems={"center"} style={{...commonStyles.articleSmall}}>
            <StyledTextParagraphLarge color={Colors.darkBlue}> TransCo Melbourne</StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> Level 15</StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> 222 Exhibition St</StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> Melbourne VIC</StyledTextParagraphLarge>
            <StyledTextParagraphLarge color={Colors.white}> 3000 Australia</StyledTextParagraphLarge>
          </Container>
        </Container>
        <a style={styles.a} target={"_blank"}
           href="https://www.google.com.au/maps/place/222+Exhibition+St,+Melbourne+VIC+3000">
          <Container
            style={{...styles.img, backgroundImage: `url(${melb})`}}
          />
        </a>
      </Container>
    }
    rightBackground={Colors.orange}
    minHeight={616}
  />
);

export {MapsAddresses};
