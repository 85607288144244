import React from 'react'
import styled from "styled-components";
import {Container} from "../container";
import {StyledText} from "../styledText";
import {Colors} from "../colors";
import logo from "../../pages/home/components/header/images/logoLight.png";
import {MaxPageWidth} from "../constants";
import headerBackground from "./images/headerBackground2.jpg";
import "./style.css"
import {Link} from "react-router-dom";


const styles = {
  container: {
    backgroundImage: `url(${headerBackground})`,
    backgroundPosition: "center 0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center",
    backgroundAttachment: "fixed"

  },
  innerContainer: {
    width: "100%",
    maxWidth: MaxPageWidth,
    justifyContent: "center",
    position: "relative"
  },
};


const PageHeader = ({headerText}) => (
  <Wrapper style={styles.container}>
      <Container style={styles.innerContainer} flexDirection={"row"} alignItems={"flex-end"}>
        <Container>
          <HeaderText color = {Colors.white} className={"page-heading"}>
            {headerText}
          </HeaderText>
        </Container>
        <Container>
          <Link to={"/"}>
          <Logo src={logo} alt={"logo"} className={"page-heading-logo"}/>
          </Link>
        </Container>
      </Container>
  </Wrapper>
);

const Wrapper = styled(Container)`
  min-height: 280px; 
`;

const HeaderText = styled(StyledText)`
`;

const Logo = styled.img`
  width: 30px; 
  position: absolute;
`;


export {PageHeader};
