import React from "react";
import {Article} from "../../../../../components/article/article";
import styled from "styled-components";
import {
      StyledTextParagraphLarge,
      StyledTextParagraphSmall
} from "../../../../../components/styledText";
import {Colors} from "../../../../../components/colors";

const PrivacyStatment = () => (
  <Article
    headerText={
          <Header>Privacy Statement</Header>
    }
    headerColor={Colors.black}
    bodyText={
      <Body>
        <p>
          TransCo Holdings Pty Ltd (ACN 635 323 849), an Australian registered company
        ("TransCo", "we", "our", "us") considers your privacy to be important
          and we take our responsibility to protect it seriously.
        </p>
        <p>
          We understand that you are concerned about your privacy,
        along with the confidentiality and security of any personal information
          that you provide to us.
        </p>
        <p>
          This privacy policy (Policy) sets out how we collect, hold, use and
        disclose information about individuals who deal or interact with us.
        We treat all personal information collected by us in accordance with the
        Privacy Act 1988 (Cth) (Privacy Act), the Australian Privacy Principles
        contained within the Privacy Act and this Policy. If there is any
        inconsistency between the Privacy Act and this Policy, the Privacy Act
          will prevail to the extent of the inconsistency.
        </p>
      </Body>
    }
  />);

const Header = styled(StyledTextParagraphLarge)``;


const Body = styled(StyledTextParagraphSmall)`
`;

export {PrivacyStatment};