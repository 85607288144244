import React from "react";
import {Layout} from "../../components/layout";
import {Policy} from "./components/policy";
import {PrivacyStatment} from "./components/sections";
import {Anonymity} from "./components/sections/anonymity";
import {PersonalInfo} from "./components/sections/personalInfo";
import {PersonalInfoCollected} from "./components/sections/personalInfoCollected";
import {PersonalInfoUse} from "./components/sections/personalInfoUse";
import {Container} from "../../components/container";

const PrivacyPolicy = () => (
  <Layout headerText={"Privacy Policy"}>
    <Container style={{width: "100%"}} alignItems={"center"}>
    <Policy
      versionNumber={"1.0"}
      alignItems={"center"}
      justfyContent={"center"}
    >
      <PrivacyStatment/>
      <Anonymity/>
      <PersonalInfo/>
      <PersonalInfoCollected/>
      <PersonalInfoUse/>
    </Policy>
    </Container>
  </Layout>

);

export {PrivacyPolicy};