import React from "react";
import styled from "styled-components";
import { Container } from "../../container";
import { Colors } from "../../colors";
import {
  StyledTextParagraphHeader,
  StyledTextParagraphLarge,
  StyledTextLinkAnchor
} from "../../styledText";

const Contact = () => (
  <Wrapper flex={"auto"} alignItems={"flex-start"}>
      <Heading>
        Contact
      </Heading>
      <Content color={Colors.blackSecondary}>
        Contact Us Today to discover what TransCo and its
        Payment Methodology can you do for you
        <br/>
        <br/>
        <StyledTextLinkAnchor href={"mailto:hello@transco.io"}>
          hello@transco.io
        </StyledTextLinkAnchor>
      </Content>
  </Wrapper>
);

const Wrapper = styled(Container)`
  margin-right: 16px; 
  padding-bottom: 40px; 
  padding-bottom: 40px; 
  max-width: 488px;
  flex: auto;
`;

const Heading = styled(StyledTextParagraphHeader)`
  padding-bottom: 16px; 
`;

const Content = styled(StyledTextParagraphLarge)``;


export { Contact };
