import React from "react";
import {Article} from "../../../../components/article/article";
import {Colors} from "../../../../components/colors";
import {SplitArticleSection} from "../../../../components/splitSection";
import styled from "styled-components";
import {Container} from "../../../../components/container";
import {StyledTextHeading2, StyledTextLink, StyledTextParagraphLarge} from "../../../../components/styledText";
import "./style.css"
import image from "./image.svg"

const IndustryMarketplace = () => (
  <SplitArticleSection
    left={
      <LeftImage
      alignItems={"center"}
      justifyContent={"center"}
      >
      <img alt={"img"} src={image} width={250}/>
      </LeftImage>
    }
    leftBackground={Colors.white}
    leftClass={"splitSectionLeftImage"}
    right={
      <Article
      headerStyle={{alignItems: "left"}}
      headerText={<StyledTextHeading2>Are you an Industry or  Marketplace Service Providers?</StyledTextHeading2>}
      bodyText={
      <StyledTextParagraphLarge>
        What do we mean by this? <br/><br/>
        Do you currently provide mobile apps, software, technology or payment gateway services
        to a group of customers in an industry? If you do and you are not talking to us you may
        not be maximising the value (RPU) of your customers.<br/><br/>
        <StyledTextLink href={"mailto:hello@transco.io"}>
          Contact us
        </StyledTextLink> to discuss what our Payments Methodology could potentially do for you.
      </StyledTextParagraphLarge>}
      wrapperClass={"article-right"}
      />
    }
    rightBackground={Colors.white}
    rightClass={"splitSectionRightText"}
  />
);

const LeftImage = styled(Container)`
  width: 100%
`;

const RightText = styled(Container)`
  max-width: 540px; 
`;


export {IndustryMarketplace}