import { MaxPageWidth } from "../../../../components/constants";

const commonStyles = {
  articleSmall: {
    maxWidth: MaxPageWidth / 2
  },
  articleFull: {
    paddingLeft: 32,
    paddingRight: 32,
    maxWidth: MaxPageWidth
  },
  articleLeft: {
    paddingLeft: 120,
    paddingRight: 60
},
  articleRight: {
    paddingLeft: 60,
    paddingRight: 120
  }
};

export { commonStyles };
