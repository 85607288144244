import React from "react";
import {Version} from "../version";
import {Container} from "../../../../components/container";
import styled from "styled-components";

const Policy = ({children,versionNumber}) => (
  <Wrapper alignItems={"flex-start"} justfyContent={"center"}>
    <Container alignItems={"center"} justfyContent={"center"} style={{paddingBottom: 24}}>
      <Version versionNumber={versionNumber}/>
    </Container>
    {children}
  </Wrapper>
);

const Wrapper = styled(Container)`
    max-width: 794px;
    padding-top: 64px; 
    padding-bottom: 64px; 
    padding-left: 32px; 
    padding-right: 32px; 
`;

export {Policy};