import React from "react";
import logo from "./images/logoLight.png";
import headerBackground from "./images/headerBackground2.jpg";
import { Container } from "../../../../components/container";
import {StyledText, StyledTextParagraphLarge} from "../../../../components/styledText";
import { Colors } from "../../../../components/colors";
import { MaxPageWidth } from "../../../../components/constants";
import styled from "styled-components";
import "./style.css"

const styles = {
  container: {
    backgroundImage: `url(${headerBackground})`,
    backgroundPosition: "center 0",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center",
    backgroundAttachment: "fixed"
},
  innerContainer: {
    width: "100%",
    maxWidth: MaxPageWidth,
    justifyContent: "center",
    position: "relative"
  },
};

const Header = () => (
  <Container style={styles.container} className={"wrapper"}>
    <Container style={styles.innerContainer} flexDirection={"row"} className={"content"}>
      <Container  className={"heading"}>
        <StyledText color = {Colors.white}>
          Your strengths... Our capabilities...
        </StyledText>
        <SubHeading color = {Colors.white} >
          A platform to generate payments revenue from your customers
        </SubHeading>
      </Container>
      <Container>
        <Logo src={logo} alt={"logo"} className={"logo"}/>
      </Container>
    </Container>
  </Container>
);


const SubHeading = styled(StyledTextParagraphLarge)`
  padding-top: 24px; 
`;

const Logo = styled.img``;

export { Header };
