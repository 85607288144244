import React from "react";
import {Article} from "../../../../components/article/article";
import {Container} from "../../../../components/container";
import {
  StyledTextLinkAnchor,
  StyledTextParagraphLarge,
  StyledTextParagraphMedium
} from "../../../../components/styledText";
import {Colors} from "../../../../components/colors/colors";
import styled from "styled-components";
import {commonStyles} from "../../../home/components/articles/commonStyles";
import "./style.css";

const styles = {
  container: {
    borderColor: Colors.misc,
    borderTopStyle: "solid",
    borderWidth: 1,
    paddingTop: 68,
    paddingBottom: 68,
    alignItems: "center",
    width: "100%"
  },
  article: {
    ...commonStyles.articleFull,
    maxWidth: 590
  },
  header: {
    paddingBottom: 24
  }
};

const GetInTouch = () => (
  <Container style={styles.container}>
  <Article
    style={styles.article}
    headerClass={"security"}
    headerText={"Get in Touch"}
    headerColor={Colors.darkBlue}
    headerStyle={styles.header}
    bodyText={
      <BodyText>
        Contact us today to discover what TransCo and its Payment Methodology can do for you.
      </BodyText>
    }

    bodyColor={Colors.blackSecondary}
    bodyClass={"security"}
  />
    <Button alignItems={"center"} justifyContent={"center"} href={"mailto:hello@transco.io"}>
      <ButtonText color={Colors.white}>
        <b>CONTACT US</b>
      </ButtonText>
    </Button>
  </Container>
);

const BodyText = styled(StyledTextParagraphLarge)`
  max-width: 590px; 
  padding-top: 24px; 
`;

const Button = styled(StyledTextLinkAnchor)`
  text-decoration: none; 
  text-align: center; 
  align-items: center
  margin-top: 40px; 
  width: 150px; 
  height: 56px; 
  border-radius: 4px; 
  background-color: ${Colors.teal}; 
`;

const ButtonText = styled(StyledTextParagraphMedium)`
  position: relative;
  top: 16px;
`;

export {GetInTouch}